<!-- 商业合作伙伴 -->
<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                <img src="@/assets/homePage/合作与发展banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <h2 class="H2">商业合作伙伴</h2>
            <img class="images_1" src="@/assets/cooperation/business_partner/合作.png" alt="">
            <div class="box_1">
                <p class="p_1">{{text}}</p>
                <img class="box_img" src="@/assets/cooperation/business_partner/盛泰客户.png" alt="">
                <div class="box_01">
                    <a :href="item.hrefS" target="_blank" v-for="item in cooperation" :key="item.id">
                        <img :src="item.src" />
                    </a>
                </div>
            </div>
            <!-- <div class="box_2">
                <img class="images_2" src="@/assets/cooperation/business_partner/盛泰荣誉png.png" alt="">

                
            </div> -->
            <div class="box_2">
                <img class="images_1" src="@/assets/cooperation/business_partner/盛泰荣誉png.png" alt="">
                <div class="text_1">
                    <li v-for="item in liList" :key="item.id">{{ item.title }}</li>
                </div>
                <img class="images_2" src="@/assets/cooperation/business_partner/荣誉奖杯.png" alt=""/>
            </div>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                <img src="@/assets/homePage/合作与发展banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <h2 >商业合作伙伴</h2>
            <img class="images_1" src="@/assets/cooperation/business_partner/合作.png" alt="">
            <p class="p_1">{{text}}</p>
            <h2>盛泰客户</h2>
            <div class="logo">
                    <a :href="item.hrefS" target="_blank" v-for="item in cooperation_1" :key="item.id">
                        <img :src="item.src" />
                    </a>
            </div>
            <h2>盛泰荣誉</h2>
            <li v-for="item in liList" :key="item.id">{{ item.title }}</li>
            <img  src="@/assets/cooperation/business_partner/荣誉奖杯.png" alt="">
        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const route = useRoute();
        const state = reactive({
            text:'盛泰光电自2006年7月成立以来，一直秉承以奋斗者为本，以客户为核心，以品质作基础，靠技术和研发永续发展的行为准则，在客户中赢得广泛赞誉，盛泰致力于服务全球一流客户为己任，争做光电百年名企。',
            cooperation: [
                { id: 10, src: require('@/assets/cooperation/business_partner/主要合作伙伴/10.png'), hrefS: 'https://www.huawei.com/cn/' },
                { id: 2, src: require('@/assets/cooperation/business_partner/主要合作伙伴/2.png'), hrefS: 'https://www.oppo.com/cn/' },
                { id: 3, src: require('@/assets/cooperation/business_partner/主要合作伙伴/3.png'), hrefS: 'http://www.vivo.com.cn/' },
                { id: 4, src: require('@/assets/cooperation/business_partner/主要合作伙伴/4.png'), hrefS: 'http://www.wingtech.com/en' },
                { id: 19, src: require('@/assets/cooperation/business_partner/主要合作伙伴/19.png'), hrefS: 'https://www.hihonor.com/cn/' },
                { id: 5, src: require('@/assets/cooperation/business_partner/主要合作伙伴/5.png'), hrefS: 'http://www.hisense.cn/' },
                { id: 6, src: require('@/assets/cooperation/business_partner/主要合作伙伴/6.png'), hrefS: 'https://www.tcl.com/cn/zh' },
                { id: 8, src: require('@/assets/cooperation/business_partner/主要合作伙伴/8.png'), hrefS: 'http://www.mobiwire.com.cn/' },
                { id: 20, src: require('@/assets/cooperation/business_partner/主要合作伙伴/20.jpg'), hrefS: 'https://www.huaqin.com/' },
                { id: 9, src: require('@/assets/cooperation/business_partner/主要合作伙伴/9.png'), hrefS: 'https://www.nokia.com/zh_int/' },
                { id: 11, src: require('@/assets/cooperation/business_partner/主要合作伙伴/11.png'), hrefS: 'https://www.samsung.com/cn/' },
                { id: 1, src: require('@/assets/cooperation/business_partner/主要合作伙伴/1.png'), hrefS: 'https://www.transsion.com/' },
                { id: 15, src: require('@/assets/cooperation/business_partner/主要合作伙伴/15.jpg'), hrefS: 'http://www.bitland.com.cn/' },
                { id: 17, src: require('@/assets/cooperation/business_partner/主要合作伙伴/17.jpg'), hrefS: 'https://www.acer.com.cn/index.html' },
                { id: 18, src: require('@/assets/cooperation/business_partner/主要合作伙伴/18.jpg'), hrefS: 'https://w3.asus.com.cn/' },
                { id: 7, src: require('@/assets/cooperation/business_partner/主要合作伙伴/7.png'), hrefS: 'https://www.zte.com.cn/china/' },
                { id: 12, src: require('@/assets/cooperation/business_partner/主要合作伙伴/12.png'), hrefS: 'http://cidi.cn/' },
                { id: 13, src: require('@/assets/cooperation/business_partner/主要合作伙伴/13.png'), hrefS: 'https://www.artiluxtech.com/' },
                { id: 14, src: require('@/assets/cooperation/business_partner/主要合作伙伴/14 .jpg'), hrefS: 'https://www.quantatw.com/Quanta/chinese/Default.aspx' },
                { id: 16, src: require('@/assets/cooperation/business_partner/主要合作伙伴/16.jpg'), hrefS: 'http://www.emdoor.com/' },
            ],
             cooperation_1: [
                { id: 10, src: require('@/assets/cooperation/business_partner/主要合作伙伴/10.png'), hrefS: 'https://www.huawei.com/cn/' },
                { id: 2, src: require('@/assets/cooperation/business_partner/主要合作伙伴/2.png'), hrefS: 'https://www.oppo.com/cn/' },
                { id: 3, src: require('@/assets/cooperation/business_partner/主要合作伙伴/3.png'), hrefS: 'http://www.vivo.com.cn/' },
                { id: 4, src: require('@/assets/cooperation/business_partner/主要合作伙伴/4.png'), hrefS: 'http://www.wingtech.com/en' },
                { id: 19, src: require('@/assets/cooperation/business_partner/主要合作伙伴/19.png'), hrefS: 'https://www.hihonor.com/cn/' },
                { id: 5, src: require('@/assets/cooperation/business_partner/主要合作伙伴/5.png'), hrefS: 'http://www.hisense.cn/' },
                { id: 6, src: require('@/assets/cooperation/business_partner/主要合作伙伴/6.png'), hrefS: 'https://www.tcl.com/cn/zh' },
                { id: 8, src: require('@/assets/cooperation/business_partner/主要合作伙伴/8.png'), hrefS: 'http://www.mobiwire.com.cn/' },
                { id: 20, src: require('@/assets/cooperation/business_partner/主要合作伙伴/20.jpg'), hrefS: 'https://www.huaqin.com/' },
                { id: 9, src: require('@/assets/cooperation/business_partner/主要合作伙伴/9.png'), hrefS: 'https://www.nokia.com/zh_int/' },
                { id: 11, src: require('@/assets/cooperation/business_partner/主要合作伙伴/11.png'), hrefS: 'https://www.samsung.com/cn/' },
                { id: 1, src: require('@/assets/cooperation/business_partner/主要合作伙伴/1.png'), hrefS: 'https://www.transsion.com/' },
                { id: 15, src: require('@/assets/cooperation/business_partner/主要合作伙伴/15.jpg'), hrefS: 'http://www.bitland.com.cn/' },
                { id: 17, src: require('@/assets/cooperation/business_partner/主要合作伙伴/17.jpg'), hrefS: 'https://www.acer.com.cn/index.html' },
                { id: 18, src: require('@/assets/cooperation/business_partner/主要合作伙伴/18.jpg'), hrefS: 'https://w3.asus.com.cn/' },
                   { id: 7, src: require('@/assets/cooperation/business_partner/主要合作伙伴/7.png'), hrefS: 'https://www.zte.com.cn/china/' },
                { id: 12, src: require('@/assets/cooperation/business_partner/主要合作伙伴/12.png'), hrefS: 'http://cidi.cn/' },
                { id: 13, src: require('@/assets/cooperation/business_partner/主要合作伙伴/13.png'), hrefS: 'https://www.artiluxtech.com/' },
            ],
            liList: [
                { id: 1, title: '传音:2020年度精诚合作伙伴' },
                { id: 2, title: '闻泰:2020年度卓越供应商' },
                { id: 3, title: '中兴:2020年度优秀质量团队' },
                { id: 4, title: '麦博:2020年度优秀质量奖' },
                { id: 5, title: '三星:2021年度最佳合作伙伴' },
                { id: 6, title: 'OPPO:2021年度优秀质量奖' },
                { id: 7, title: '传音:2021最具价值合作伙伴' },
                { id: 8, title: '闻泰:2021年度质量优秀奖' },
                { id: 9, title: '海信:2021战略供应商' },
            ]
        });
        onMounted(() => {
        });
        return {
            ...toRefs(state),
        };
    },
};
</script>

<style scoped lang="less">
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;

        .box_1 {
            position: relative;
            margin-top: 100px;
            // display: flex;
            .box_01{
                position: absolute;
                top: 250px;left: 300px;
                width: 80%;
                
            }
            .box_img{
                width: 100%;
                height: 800px;
            }
            .p_1{
                position: absolute;
                left: 180px;
                width: 90%;
                font-size: 1.2rem;
            }
            .images_1 {
                flex: 1;
                box-sizing: border-box;
                padding: 30px;
                width: 300px;
            }

            div {
                flex: 3;
                display: flex;
                flex-wrap: wrap;
                // border: 1px solid #ccc;

                a {
                    width: 20%;

                }

                a:nth-child(2) {
                    padding-top: 10px;
                }

                a:nth-child(4) {
                    padding-top: 10px;
                }
                a:nth-child(5) {
                    padding-top: 10px;
                }

                a:nth-child(11) {
                    padding-top: 10px;
                }

                a:nth-child(16) {
                    padding-top: 35px;
                }

                a:nth-child(17) {
                    padding-top: 40px;
                }

                a:nth-child(18) {
                    padding-top: 20px;
                }

                img {
                    margin: 0 auto;
                    flex: 1;
                    cursor: pointer;
                    width: 100%;
                    margin: auto;
                    padding: 20px 30px;
                    box-sizing: border-box;
                }
            }
        }
        .box_2{
            position: relative;
            height: 750px;
            .images_1{
                position: absolute;
                left: 130px;
                top: -80px;
                width: 95%;
            }
            .text_1{
                position: absolute;
                left: 100px;top: 100px;
                font-size: 1.2rem;
                line-height: 50px;
            }
            .images_2{
                position: absolute;
                left: 480px;
                top: 90px;
                width: 600px;
            }
        }
        

        .images_1 {
            width: 100%;
        }

    

        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 19%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    }
}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .content {
            padding: 30px;
            li{
                font-size: 50px;
                margin: 20px;
                margin-left: 50%;
                transform: translateX(-40%);
            }
            img{
                width: 100%;
            }
            .p_1{
                font-size: 50px;
                font-weight: bold;
                padding: 0 30px;
            }
            .logo{
               display: flex;
               flex-wrap: wrap;
               a:nth-child(2){
                padding-top: 70px;
               }
               a:nth-child(3){
                padding-top: 20px;
               }
               a:nth-child(4){
                padding-top: 70px;
               }
               a:nth-child(5){
                padding-top: 40px;
               }
               a:nth-child(6){
                padding-top: 70px;
               }
               a:nth-child(12){
                padding-top: 10px;
               }
               a:nth-child(18){
                padding-top: 10px;
                padding-left: 40px;
               }
               a{
                box-sizing: border-box;
                padding: 50px;
                max-width: 33%;
                min-width: 33%;
                
                img{width: 100%;}
               }
            }
            h2 {
                text-align: center;
                font-size: 80px;
            }

            h2::before {
                content: "";
                width: 30%;
                height: 8px;
                background-color: #c7000b;
                position: absolute;
                margin-top: 100px;
                left: 50%;
                transform: translateX(-50%);
                min-width: 100px;
                max-width: 100px;
            }
        }
    }
}
</style>